import { cn } from '@/core/ui/utils';
import { FC } from '@/types/common';

import { FormattedPrice } from '../FormattedPrice/FormattedPrice';

const asterisk = '*';

export const OldPrice: FC<{
  currencyCode: string;
  centAmount: number;
  color?: string;
  showAsterisk?: boolean;
  className?: string;
}> = ({ centAmount, currencyCode, color, showAsterisk = true, className }) => (
  <span
    className={cn('line-through', color || 'text-text-subtlest', className)}
  >
    <FormattedPrice centAmount={centAmount} currencyCode={currencyCode} />
    {showAsterisk && asterisk}
  </span>
);
